import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  BoldParagraph2,
  Heading3,
  Paragraph1c,
  Paragraph3b,
} from "../../theme/typography";
import { useMode } from "../../store/mode/hooks";
import { MODE } from "../../store/mode";
import { componentColor } from "../../theme/colors";
import { BREAKPOINTS } from "../../theme/breakpoints";
import moment from "moment";
import { useParams } from "react-router-dom";
// import { useBlogData } from "../../store/blogData/hooks";
import LikeSection from "../../component/BlogPageComponent/LikeSection.tsx";
import { HideOn } from "react-hide-on-scroll";
import { blogs } from "../../constant/blogPosts";
import { Helmet } from "react-helmet-async";

const MainDiv = styled.div<{ mode: MODE }>`
  width: 78%;
  border-radius: 8px;
  background-color: ${(props) =>
    props.mode === MODE.DARK
      ? componentColor.secondary
      : componentColor.primary};
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 40px;
  align-items: flex-start;
  gap: 10px;
  position: relative;
  top: -60px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 82%;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 88%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 94%;
    padding:30px 20px;
  }
`;
const GreenSmallDot = styled.div`
  height: 4px;
  width: 4px;
  background-color: #03c988;
  border-radius: 99px;
`;
const WhiteSmallDot = styled.div`
  height: 4px;
  width: 4px;
  background-color: #d9d9d9;
  border-radius: 99px;
`;

const CategoryLabelDiv = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  row-gap: 15px;
  flex-wrap: wrap;
`;
const AuthorAndDateDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  @media (max-width: ${BREAKPOINTS.sm}px){
    flex-direction: column;
    gap: 0px;
  }
`;
const Image = styled.img`
  border-radius: 8px;
  object-fit: cover;
  width: 100%;
  height: 310px;
  @media (max-width: 500px){
    height: 200px;
  }
`;
const CustomHeading2 = styled(Heading3)`
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 34px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 24px;
    font-weight: 500;
    margin-top: 10px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 20px;
    font-weight: 500;
    margin-top: 0px;
  }
`;
const CustomBoldParagraph2 = styled(BoldParagraph2)`
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 14px;
    font-weight: 500;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin-top: 15px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    margin-top: 5px;
  }
`;


const CustomParagraph3b = styled(Paragraph3b)`
  text-transform: uppercase;
`;
const CustomParagraph1c = styled(Paragraph1c)`
  /* text-align: justify; */
  padding: 0px 50px;
  text-align: justify;
  @media (max-width: 900px) {
    
    padding: 0px 0px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 14px;
    padding: 0px 0px;
  }
`;
const Index = () => {
  const mode = useMode();
  const [blogData, setBlogData] = useState<any[]>([]);
  // const blogs = useBlogData();
  const { permalink } = useParams<{ permalink?: string }>();
  const blog = blogData?.find((blog) => blog?.permalink === (permalink)); 

  useEffect(() => {
    setBlogData(blogs);
  }, []);
 
  const formattedDate = moment(blog?.createdAt).format("MMMM Do YYYY, h:mm a");

  return (
    <MainDiv mode={mode} >
      <HideOn height={1200}>
        <LikeSection image={blog?.imageUrl} title={blog?.title}/>
      </HideOn>
      <CategoryLabelDiv>
        {blog?.category?.map((data: any) => (
          <CustomParagraph3b
            mode={mode}
            style={{ display: "flex", alignItems: "center", gap: "4px" }}
          >
            <GreenSmallDot />
            {data}
          </CustomParagraph3b>
        ))}
      </CategoryLabelDiv>
      <CustomHeading2 mode={mode}>{blog?.title}</CustomHeading2>

      <Helmet>
        <title>{blog?.title}</title>
        <meta property="og:url" content={blog?.permalink} /> 
        <meta name="description" content={blog?.metaDescription} />
        <meta property="og:title" content={blog?.title} />
        <meta property="og:description" content={blog?.metaDescription} />
        <meta property="og:image" content={ blog?.imageUrl} />
        <meta property="og:type" content="Blogs" />
        <meta property="og:site_name" content="Buckmint" />
      </Helmet>

      <AuthorAndDateDiv>
        <CustomBoldParagraph2
          mode={mode}
          style={{ display: "flex", alignItems: "center", gap: "4px" }}
        >
          <WhiteSmallDot />
          {/*  */}
          Buckmint Team (Author)
          {/*  */}
        </CustomBoldParagraph2>
        <CustomBoldParagraph2
          mode={mode}
          style={{ display: "flex", alignItems: "center", gap: "4px" }}
        >
          <WhiteSmallDot />
          {formattedDate}
        </CustomBoldParagraph2>
      </AuthorAndDateDiv>
      {/* <CustomHeading5 mode={mode} style={{ marginBottom: "5px" }}>
        {blog?.metaDescription}
      </CustomHeading5> */}
      <Image src={ blog?.imageUrl} alt={blog?.imageAltText} />
      <CustomParagraph1c className="description" mode={mode}
       dangerouslySetInnerHTML={{__html: blog?.content}}
      />
      {/* <CustomParagraph1c mode={mode}>
        {BlogContent[0].MoreDetail}
      </CustomParagraph1c> */}
    </MainDiv>
  );
};

export default Index;
