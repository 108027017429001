import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TextColor, {
  Heading6,
  Paragraph1,
  Paragraph2,
} from "../../theme/typography";
import blackLogo from "../../asset/svgs/buckmint-black-logo.svg";
import whiteLogo from "../../asset/svgs/buckmint-white-logo.svg";
import dot from "../../asset/svgs/ellipse-dot.svg";
import whiteDot from "../../asset/svgs/white-dot.svg";
import { useMode } from "../../store/mode/hooks";
import { MODE } from "../../store/mode";
import { Link } from "react-router-dom";
import { BREAKPOINTS } from "../../theme/breakpoints";
import moment from "moment";
import { blogs } from "../../constant/blogPosts";

const MainDiv = styled.div`
  width: 78%;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 82%;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 88%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 94%;
  }
`;
const BigCardDiv = styled.div<{length:number}>`
  /* width: calc(68% - 23px); */
  width: ${(props)=>props.length===1?'100%': "calc(68% - 23px)"};
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  @media (max-width: ${BREAKPOINTS.xxl}px) {
    width: calc(60% - 23px);
    width: ${(props)=>props.length===1?'100%': "calc(60% - 23px)"};
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: calc(53% - 21px);
    width: ${(props)=>props.length===1?'100%': "calc(53% - 21px)"};
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 100%;
  }
`;
const BigCardImage = styled.img`
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
  height: 260px;
  @media (max-width: ${BREAKPOINTS.xxl}px) {
    height: 260px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    height: 210px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 280px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: 250px;
  }
`;
const SecondCardDiv = styled.div`
  width: 32%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINTS.xxl}px) {
    width: 40%;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 47%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    display: none;
  }
`;
const SmallCardImage = styled.img<{length:number}>`
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
  height: 128px;
  height: ${(props)=>props.length===3?'128px':"370px"};
  @media (max-width: ${BREAKPOINTS.xxl}px) {
    height: 138px;
    height: ${(props)=>props.length===3?' 138px':"350px"};
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    height: 158px;
    height: ${(props)=>props.length===3?' 158px':"370px"};
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: 120px;
    height: ${(props)=>props.length===3?' 120px':"340px"};
  }
`;
const SmallCardDiv = styled.div`
  position: relative;
`;
const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const PublisherDiv = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;
const LogoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
  width: 56px;
  height: 56px;
  fill: var(--layers-layer-150900, #fafafa);
  filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.04));
`;
const TagBox = styled.div`
  display: flex;
  gap: 13px;
  position: absolute;
  top: 12px;
  left: 12px;
  flex-wrap: wrap;
`;
const Tag = styled.div<{ mode: MODE }>`
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: ${(props) => (props.mode === MODE.DARK ? "#424242" : "#e0e0e0")};
  /* Text */
  color: ${(props) =>
    props.mode === MODE.DARK ? TextColor.secondary : TextColor.primary};
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%; /* 19.92px */
  letter-spacing: 0.4px;
`;
const CustomHeading6 = styled(Heading6)`
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 20px;
    font-weight: 500;
  }
`;
const CustomParagraph2 = styled(Paragraph2)`
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 12px;
  }
`;

const BlogCard = () => {
  const mode = useMode();
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [blogData, setBlogData] = useState<any[]>([]);
  
  const length = blogs.length
  useEffect(() => {
    const sortedBlogs = blogs?.sort((a: any, b: any) =>
      moment(b.createdAt).diff(moment(a.createdAt))
    );
    setBlogData(sortedBlogs);
  }, []);
  const cleanHtmlContent = (htmlContent:any) => {
    if (!htmlContent) return "";
    return htmlContent.replace(/<br\s*\/?>/gi, "");
  };

  return (
    <MainDiv>
      <BigCardDiv length={length}>
          {(
        <Link
          to={`/detail/${blogData[0]?.permalink}`}
          onClick={() => window.scrollTo(0, 0)}
          key={blogData[0]?.id}
        >
            <BigCardImage
              loading="lazy" 
              onMouseEnter={() => setHoveredIndex(0)}
              onMouseLeave={() => setHoveredIndex(null)}
              src={blogData[0]?.imageUrl}
              alt=""
              key={blogData[0]?.id}
            />
            </Link>
          )}
        <TagBox>
          {blogData[0]?.category?.map((data: any, index: number) =>
            (
              <Tag mode={mode} key={index}>
                {data}
              </Tag>
            )
          )}
        </TagBox>
        <TextDiv>
          <CustomHeading6 mode={mode}>
            {
            hoveredIndex !== 0 ? (
              blogData[0]?.title?.length > 130 ? (
                blogData[0]?.title.substr(0, 130) + " ...."
              ) : (
                blogData[0]?.title
              )
            ) : (
              blogData[0]?.title
            )
            }
          </CustomHeading6>
          <CustomParagraph2 mode={mode} dangerouslySetInnerHTML={{__html:  cleanHtmlContent(blogData[0]?.content).substr(0, 300) + " ...."}}/>
          
          {(
            <PublisherDiv>
              <LogoDiv>
                <img
                  style={{ width: "32px", height: "32px" }}
                  src={mode === MODE.DARK ? whiteLogo : blackLogo}
                  alt=""
                />
              </LogoDiv>
              <CustomParagraph2 mode={mode}>Buckmint Team</CustomParagraph2>
              <img src={mode === MODE.DARK ? whiteDot : dot} alt="" />
              <CustomParagraph2 mode={mode}>
                {moment(blogData[0]?.createdAt).format("MMMM Do YYYY")}
              </CustomParagraph2>
            </PublisherDiv>
          )}
        </TextDiv>
      </BigCardDiv>
      {/*  */}
      {length!==1 && 
      <SecondCardDiv>
          <Link
            to={`/detail/${blogData[1]?.permalink}`}
            onClick={() => window.scrollTo(0, 0)}
            key={blogData[1]?.id}
          >
       <SmallCardDiv>
            {(
              <SmallCardImage
              length={length}
                onMouseEnter={() => setHoveredIndex(1)}
                onMouseLeave={() => setHoveredIndex(null)}
                src={ blogData[1]?.imageUrl}
                alt=""
                key={blogData[1]?.id}
              />
            )}

          <TagBox>
            {blogData[1]?.category?.map((data: any, index: number) =>
              (
                <Tag mode={mode} key={index}>
                  {data}
                </Tag>
              )
            )}
          </TagBox>
          <TextDiv>
            <Paragraph1
              mode={mode}
              style={{
                color:
                mode === MODE.DARK
                ? "rgba(255, 255, 255, 0.87)"
                : "rgba(0, 0, 0, 0.87)",
              }}
              >
              {hoveredIndex !== 1 ? (
                blogData[1]?.title?.length > 40 ? (
                  blogData[1]?.title.substr(0, 37) + " ...."
                ) : (
                  blogData[1]?.title
                )
              ) : (
                blogData[1]?.title
              )}
            </Paragraph1>
          </TextDiv>
          {(
            <PublisherDiv>
              <LogoDiv>
                <img
                  style={{ width: "32px", height: "32px" }}
                  src={mode === MODE.DARK ? whiteLogo : blackLogo}
                  alt=""
                  />
              </LogoDiv>
              <CustomParagraph2 mode={mode}>Buckmint Team</CustomParagraph2>
              <img src={mode === MODE.DARK ? whiteDot : dot} alt="" />
              <CustomParagraph2 mode={mode}>
                {moment(blogData[1]?.createdAt).format("MMMM Do YYYY")}
              </CustomParagraph2>
            </PublisherDiv>
          )}
        </SmallCardDiv>
          </Link>
        {/*  */}
      { length===3 && 
          <Link
            to={`/detail/${blogData[2]?.permalink}`}
            onClick={() => window.scrollTo(0, 0)}
            key={blogData[2]?.id}
          >
      <SmallCardDiv>
            {
            (
              <SmallCardImage
                loading="lazy" 
                length={length}
                onMouseEnter={() => setHoveredIndex(2)}
                onMouseLeave={() => setHoveredIndex(null)}
                src={ blogData[2]?.imageUrl}
                alt=""
                key={blogData[2]?.id}
              />
            )}
          <TagBox>
            {blogData[2]?.category?.map((data: any, index: number) =>
               (
                 <Tag mode={mode} key={index}>
                  {data}
                </Tag>
              )
            )}
          </TagBox>
          <TextDiv>
            <Paragraph1
              mode={mode}
              style={{
                color:
                mode === MODE.DARK
                ? "rgba(255, 255, 255, 0.87)"
                : "rgba(0, 0, 0, 0.87)",
              }}
              >
              {hoveredIndex !== 2 ? (
                blogData[2]?.title?.length > 40 ? (
                  blogData[2]?.title.substr(0, 37) + " ...."
                ) : (
                  blogData[2]?.title
                )
              ) : (
                blogData[2]?.title
              )}
            </Paragraph1>
            {/*  */}
          </TextDiv>
          {(
            <PublisherDiv>
              <LogoDiv>
                <img
                  style={{ width: "32px", height: "32px" }}
                  src={mode === MODE.DARK ? whiteLogo : blackLogo}
                  alt=""
                  />
              </LogoDiv>
              <CustomParagraph2 mode={mode}>Buckmint Team</CustomParagraph2>
              <img src={mode === MODE.DARK ? whiteDot : dot} alt="" />
              <CustomParagraph2 mode={mode}>
                {moment(blogData[2]?.createdAt).format("MMMM Do YYYY")}
              </CustomParagraph2>
            </PublisherDiv>
          )}
        </SmallCardDiv>
          </Link>
        }
      </SecondCardDiv>}
    </MainDiv>
  );
};

export default BlogCard;
